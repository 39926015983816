import React from 'react'

function Footer() {
  return (
    <footer>
      <p>Copyright &copy; 2022.</p>
    </footer>
  )
}

export default Footer